<template>
  <div></div>
</template>

<script>
import invitation from "../../api/invitation";

export default {
  data() {
    return {
    };
  },
  methods: {},
  created() {
    localStorage.setItem("isok", true);
    console.log('走到这里了')
    if (this.$route.query.code) {
      //如果连接中能拿到code说明此时access_token没有或者已过期，需要重新获取token
      invitation
        .auth({
          code: this.$route.query.code,
        })
        .then(res => {
          if (res.code == 200) {
            localStorage.setItem("userid", res.data);
            // this.$router.push({
            //   path: "invitation"
            // });
               window.location.href = "/invitation";
            // this.$router.go(-1);
          } else {
            this.Toast(res.data.msg);
          }
        });
    }
  }
};
</script>
